<template>
	<Offer>
		<template #banner>
			<img slot="banner" v-if="mobile === false" src="../../../../../assets/images/banners/ecommerce.png" alt="banner">
			<img slot="banner" v-else src="../../../../../assets/images/mobile/ecommerce.png" alt="banner">
		</template>

		<template #button>
			<i v-if="mobile === true" class="fas fa-arrow-left"></i>
			<router-link slot="button" to="/oferta">Wróć do spisu usług</router-link>
		</template>

		<template #small-title>
			<h4>PRAWO GOSPODARCZE</h4>
		</template>

		<template #title>
			<h2>E-commerce</h2>
		</template>

		<template #text>
			<p>E -commerce , czyli ogólnie mówiąc handel elektroniczny jest w ostatnich latach bardzo dynamicznie
				rozwijającym się kanałem dystrybucji. Wychodząc naprzeciw oczekiwaniom naszych Klientów, oferujemy im
				kompleksową obsługę związaną z e-commerce, a także doradzamy w zakresie ochrony marki w środkach
				komunikacji elektronicznej. Tworzymy i opiniujemy regulaminy, polityki prywatności, jak również
				zapewniamy ich zgodność i aktualność ze zmieniającymi się przepisami.
			</p>

			<p>Obsługujemy zarówno sklepy internetowe, jak i administratorów serwisów, dostawców usług świadczonych
				drogą elektroniczną, czy dostawców treści w Internecie. W naszej ofercie można znaleźć również pomoc
				przy tworzeniu modeli współpracy B2B (Business to Business) oraz B2C (Business to Customer).</p>
		</template>

		<template #range>
			<h3>ZAKRES:
			</h3>
		</template>
		<template #list>
			<ul class="offer__list">
				<li class="offer__list-item">Tworzenie i opiniowanie regulaminów i polityk prywatności</li>
				<li class="offer__list-item">Przeprowadzanie audytu prawnego stosowanych rozwiązań oraz przygotowanie raportu zawierającego listę nieprawidłowości oraz sugerowane działania dla serwisu lub sklepu</li>
				<li class="offer__list-item">Marketing internetowy</li>
				<li class="offer__list-item">Realizacja praw konsumenckich oraz pomoc w sporach konsumenckich</li>
				<li class="offer__list-item">Wdrożenia RODO</li>
				<li class="offer__list-item">Pomoc w zwalczaniu czynów nieuczciwej konkurencji</li>
			</ul>
		</template>
	</Offer>
</template>

<script>
import Offer from '../Offer'
import { handleWith } from "@/mixins/globalMixins";

export default {
	components: {
		Offer
	},
	name: 'e-commerce',
	mixins: [handleWith],
}
</script>
